<script setup lang="ts">
import { ref, onMounted } from "vue";
import { MoonIcon, SunIcon } from "@heroicons/vue/outline";

const darkMode = ref(false);

onMounted(() => {
  darkMode.value =
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches);
});

function enableDarkMode() {
  darkMode.value = true;
  localStorage.theme = "dark";
  document.documentElement.classList.add("dark");
}

function enableLightMode() {
  darkMode.value = false;
  localStorage.theme = "light";
  if (document.documentElement.classList.contains("dark")) {
    document.documentElement.classList.remove("dark");
  }
}
</script>

<template>
  <button
    type="button"
    class="bg-white dark:bg-dark-600 p-1 rounded-full text-gray-500 dark:text-dark-300 hover:text-gray-800 dark:hover:text-dark-100 focus:outline-none"
  >
    <span class="sr-only">Select Dark/Light Mode</span>
    <SunIcon
      v-if="darkMode"
      @click="enableLightMode"
      title="Turn on Light Mode (white background)"
      class="h-6 w-6"
      aria-hidden="true"
    />
    <MoonIcon
      v-else
      @click="enableDarkMode"
      title="Turn on Dark Mode (black background)"
      class="h-6 w-6"
      aria-hidden="true"
    />
  </button>
</template>
