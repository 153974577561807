<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  UserIcon,
  BellIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import ThemeSelector from "@/components/ThemeSelector.vue";
import { useUserStore } from "@/stores/UserStore";

const userStore = useUserStore();

const user = {
  name: "Patrick Glass",
  email: "pglass@swisstech.ca",
  imageUrl:
    "https://en.gravatar.com/userimage/13226765/5afa99196c0a683ef74b783601fc8080.jpeg",
};
const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "Profile", href: "/profile", current: false },
  { name: "About", href: "/about", current: false },
  { name: "Contact", href: "/contact", current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Settings", href: "/settings" },
  { name: "Sign out", href: "/logout" },
];
</script>

<template>
  <nav>
    <div class="min-h-full">
      <Disclosure as="nav" class="bg-white dark:bg-dark-600" v-slot="{ open }">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between h-16">
            <div class="flex items-center">
              <div class="flex-shrink-0 content-center">
                <a href="/">
                  <img
                    class="h-8 w-8"
                    src="../assets/logo.svg"
                    alt="HAL Platform"
                  />
                  <span class="invisible sm:visible text-xl sr-only"
                    >HAL Platform</span
                  >
                </a>
              </div>
              <div class="hidden md:block">
                <div class="ml-10 flex items-baseline space-x-4">
                  <router-link
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    :class="[
                      item.current
                        ? 'bg-gray-50 dark:bg-dark-500 text-gray-800 dark:text-dark-100 font-bold'
                        : 'text-gray-500 dark:text-dark-400 dark:hover:bg-dark-500 hover:text-gray-900 hover:bg-gray-100 dark:hover:text-dark-100',
                      'px-3 py-2 rounded-md text-sm font-medium',
                    ]"
                    :aria-current="item.current ? 'page' : undefined"
                    >{{ item.name }}
                  </router-link>
                </div>
              </div>
            </div>

            <div class="hidden md:block">
              <div
                v-if="!userStore.isAuthenticated"
                class="ml-4 flex items-center md:ml-6"
              >
                <router-link
                  to="/login"
                  class="px-3 py-2 rounded-md text-sm font-medium text-gray-500 dark:text-dark-400 dark:hover:bg-dark-500 hover:text-gray-900 hover:bg-gray-100 dark:hover:text-dark-100"
                >
                  Login
                </router-link>
              </div>
              <div v-else class="ml-4 flex items-center md:ml-6">
                <ThemeSelector v-if="!userStore.isAuthenticated" />
                <button
                  v-if="!userStore.isAuthenticated"
                  type="button"
                  class="bg-white dark:bg-dark-600 p-1 rounded-full text-gray-500 dark:text-dark-300 hover:text-gray-800 dark:hover:text-dark-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span>Login</span>
                  <UserIcon class="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                  v-if="userStore.isAuthenticated"
                  type="button"
                  class="bg-white dark:bg-dark-600 p-1 rounded-full text-gray-500 dark:text-dark-300 hover:text-gray-800 dark:hover:text-dark-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span class="sr-only">View notifications</span>
                  <BellIcon class="h-6 w-6" aria-hidden="true" />
                </button>

                <!-- Profile dropdown -->
                <Menu as="div" class="ml-3 relative">
                  <div>
                    <MenuButton
                      class="max-w-xs bg-white dark:bg-dark-700 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                      <span class="sr-only">Open user menu</span>
                      <img
                        class="h-8 w-8 rounded-full"
                        :src="user.imageUrl"
                        alt=""
                      />
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem
                        v-for="item in userNavigation"
                        :key="item.name"
                        v-slot="{ active }"
                      >
                        <a
                          :href="item.href"
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          ]"
                          >{{ item.name }}</a
                        >
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
            <div class="-mr-2 flex md:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton
                class="bg-white dark:bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-500 dark:text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span class="sr-only">Open main menu</span>
                <Bars3Icon
                  v-if="!open"
                  class="block h-6 w-6"
                  aria-hidden="true"
                />
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>

        <DisclosurePanel class="md:hidden">
          <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <DisclosureButton
              v-for="item in navigation"
              :key="item.name"
              as="a"
              :href="item.href"
              :class="[
                item.current
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</DisclosureButton
            >
          </div>
          <div class="pt-4 pb-3 border-t border-gray-700">
            <div class="flex items-center px-5">
              <div class="flex-shrink-0">
                <img
                  class="h-10 w-10 rounded-full"
                  :src="user.imageUrl"
                  alt=""
                />
              </div>
              <div class="ml-3">
                <div
                  class="text-base font-medium leading-none text-gray-600 dark:text-gray-400"
                >
                  {{ user.name }}
                </div>
                <div
                  class="text-sm font-medium leading-none text-gray-500 dark:text-gray-400"
                >
                  {{ user.email }}
                </div>
              </div>
              <button
                type="button"
                class="ml-auto bg-white dark:bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-500 dark:text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-3 px-2 space-y-1">
              <DisclosureButton
                v-for="item in userNavigation"
                :key="item.name"
                as="a"
                :href="item.href"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-500 dark:text-gray-400 hover:text-white hover:bg-gray-700"
                >{{ item.name }}</DisclosureButton
              >
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </nav>
</template>

<style scoped></style>
