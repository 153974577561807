import router from "@/router";
import { defineStore } from "pinia";
import { Auth0Client } from "@auth0/auth0-spa-js";

// getMetaContent returns the content for custom meta tags
function getMetaContent(name: string) {
  const metaElement = document.head.querySelector(
    `meta[name="${name}"]`
  ) as HTMLMetaElement;
  return metaElement.content;
}

console.log(getMetaContent("auth-client-id"));

export const useUserStore = defineStore("user", {
  state: () => ({
    id: "",
    name: "",
    username: "",
    email: "",
    imageURL: "",
    errorMessage: "",
    accessToken: "",
    idToken: "",
    expiry: 0,
    auth0Client: new Auth0Client({
      domain: getMetaContent("auth-domain"),
      client_id: getMetaContent("auth-client-id"),
      responseType: "token, id_token",
      scope: "openid, profile, email",
      cacheLocation: "localstorage",
    }),
    isAuthenticated: false,
  }),
  // getters: {
  //   isAuthenticated: (state) => state.isAuthenticated,
  // },
  actions: {
    login(email: string, redirect: string) {
      // save original location so we can redirect back
      localStorage.setItem("redirectURL", redirect);
      this.auth0Client.loginWithRedirect({
        login_hint: email,
        redirect_uri:
          window.location.protocol +
          "//" +
          window.location.host +
          process.env.BASE_URL +
          "login/callback",
      });
    },
    logout() {
      this.isAuthenticated = false;
      localStorage.removeItem("redirectURL");
      this.auth0Client.logout({
        returnTo: window.location.origin,
      });
    },
    async checkSessionIsAuthenticated() {
      if (this.id == "") {
        console.log("user data not found, checking localstorage");
        await this.auth0Client.checkSession();
        await this.fetchUserDetails();
      }
      return this.id.length !== 0;
    },
    handleRedirectCallback() {
      this.auth0Client
        .handleRedirectCallback()
        .then((redirectResult) => {
          console.log(redirectResult);
          // logged in. you can get the user profile
          this.fetchUserDetails();
          // If successfull redirect to original page
          const originalURL = localStorage.getItem("redirectURL");
          if (originalURL) {
            router.push(originalURL);
          }
        })
        .catch((error) => (this.errorMessage = error));
    },
    async fetchUserDetails() {
      try {
        const user = await this.auth0Client.getUser();
        if (user !== undefined) {
          this.id = user.sub ?? "";
          this.name = user.name ?? "";
          this.username = user.nickname ?? "";
          this.imageURL = user.picture ?? "";
          this.email = user.email ?? "";
        } else {
          this.isAuthenticated = false;
          this.errorMessage = "could not get user details";
        }
        const token = await this.auth0Client.getTokenSilently();
        this.accessToken = token;
        this.isAuthenticated = true;
      } catch (e) {
        if (typeof e === "string") {
          this.errorMessage = e;
        } else {
          console.error(e);
        }
      }
    },
  },
});
