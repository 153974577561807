<template>
  <footer
    class="h-10 p-4 bg-gray-100 dark:bg-dark-800 shadow md:flex md:items-center md:justify-between md:p-6"
  >
    <span class="text-sm sm:text-center text-gray-500 dark:text-dark-400"
      >© 2022
      <a href="https://halplatform.com/" class="hover:underline"
        >HAL Platform™</a
      >. All Rights Reserved.
    </span>
    <ul
      class="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-dark-400 sm:mt-0"
    >
      <li>
        <router-link to="/about" class="mr-4 hover:underline md:mr-6"
          >About</router-link
        >
      </li>
      <li>
        <router-link to="/privacy" class="mr-4 hover:underline md:mr-6"
          >Privacy Policy</router-link
        >
      </li>
      <li>
        <router-link to="/terms" class="mr-4 hover:underline md:mr-6"
          >Terms and Conditions</router-link
        >
      </li>
      <li>
        <router-link to="#" class="mr-4 hover:underline md:mr-6"
          >Licensing</router-link
        >
      </li>
      <li>
        <router-link to="/contact" class="hover:underline">Contact</router-link>
      </li>
    </ul>
  </footer>
</template>

<style scoped>
/* footer {
  position: fixed;
  width: 100%;
  bottom: 0;
} */
</style>
