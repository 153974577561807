import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useUserStore } from "@/stores/UserStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: false },
    component: HomeView,
  },
  {
    path: "/profile",
    name: "profile",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/LoginView.vue"),
  },
  {
    path: "/login/callback",
    name: "logincallback",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/AuthCallback.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/login/LogoutView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/PrivacyView.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const userStore = useUserStore();

  // check if we are on auth callback url
  if (to.path == "/login/callback") {
    userStore.handleRedirectCallback();
  }
  // prompt for login if requiresAuth undefined or true
  if (
    (to.meta.requiresAuth === undefined || to.meta.requiresAuth) &&
    !userStore.checkSessionIsAuthenticated()
  ) {
    // this route requires auth, check if user is logged in
    // if not, redirect to login page.
    return {
      path: "/login",
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
});

export default router;
