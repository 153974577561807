<script setup lang="ts">
import SiteHeader from "@/components/SiteHeader.vue";
import SiteFooter from "@/components/SiteFooter.vue";

interface LayoutDefaultProps {
  title?: string;
}
const props = defineProps<LayoutDefaultProps>();
</script>

<template>
  <div class="flex flex-col h-screen justify-between">
    <SiteHeader />
    <header v-if="props.title" class="bg-white dark:bg-dark-700 shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-gray-800 dark:text-dark-300">
          {{ props.title }}
        </h1>
      </div>
    </header>
    <main class="mb-auto max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <slot>
        <!-- Replace with your content -->
        <div class="px-4 py-6 sm:px-0">
          <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />
        </div>
        <!-- /End replace -->
      </slot>
    </main>
    <SiteFooter />
  </div>
</template>
